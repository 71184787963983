import React from "react";
import { Trans } from "react-i18next";
import "../layout.css";

const RenderFlexbox = () => {
  return (
    <ul className="references-flexbox">
      <li className="references-flexbox-item">
        <img
          alt="Item 1"
          src="https://www.surya-digital.com/wp-content/uploads/2017/10/ref_1.png"
        />
        <p style={{ marginLeft: `auto`, marginRight: `auto` }}>
          <Trans i18nKey="Flexibility" />
        </p>
      </li>

      <li className="references-flexbox-item">
        <img
          alt="Item 2"
          src="https://www.surya-digital.com/wp-content/uploads/2017/10/ref_2.png"
        />
        <p style={{ marginLeft: `auto`, marginRight: `auto` }}>
          <Trans i18nKey="Reactivity" />
        </p>
      </li>

      <li className="references-flexbox-item">
        <img
          alt="Item 3"
          src="https://www.surya-digital.com/wp-content/uploads/2017/10/ref_3.png"
        />
        <p style={{ marginLeft: `auto`, marginRight: `auto` }}>
          <Trans i18nKey="Adaptability" />
        </p>
      </li>

      <li className="references-flexbox-item">
        <img
          alt="Item 4"
          src="https://www.surya-digital.com/wp-content/uploads/2017/10/ref_4.png"
          style={{ margin: `auto` }}
        />
        <p style={{ marginLeft: `auto`, marginRight: `auto` }}>
          <Trans i18nKey="Transparency" />
        </p>
      </li>
    </ul>
  );
};

export default RenderFlexbox;
