import React from "react";
import { Trans } from "react-i18next";
import { Icon } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import li_bg from "../../../assets/li_bg.png";
import "../layout.css";

const ReferenceList = () => {
  return (
    <div className="references-list">
      <li>
        <img src={li_bg} alt="li icon" style={{ padding: `0 20px` }} />
        <Trans i18nKey="Major French Luxury Brands" />
      </li>
      <li>
        <img src={li_bg} alt="li icon" style={{ padding: `0 20px` }} />
        <Trans i18nKey="International Banks" />
      </li>
      <li>
        <img src={li_bg} alt="li icon" style={{ padding: `0 20px` }} />
        <Trans i18nKey="Communication Agencies" />
      </li>
      <li>
        <img src={li_bg} alt="li icon" style={{ padding: `0 20px` }} />
        <Trans i18nKey="startups" />
      </li>
      <li>
        <img src={li_bg} alt="li icon" style={{ padding: `0 20px` }} />
        <Trans i18nKey="multinationals" />
      </li>
    </div>
  );
};

export default ReferenceList;
