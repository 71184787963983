import React from "react";
import { Trans, withTranslation } from "react-i18next";
import ContainerWithHeaderOnly from "../components/containerWithHeaderOnly";
import Layout from "../components/layout";
import "../components/layout.css";
import ReferenceList from "../components/references/referenceList";
import RenderFlexbox from "../components/references/renderFlexbox";

class References extends React.Component {
  public render() {
    return (
      <Layout>
        <div className="references-empty-div" />
        <div
          className="references-container-header-div"
          style={{
            background: `transparent`,
            marginBottom: `-7.5%`,
            overflow: `hidden`
          }}
        >
          <img
            className="container-header"
            src="https://www.surya-digital.com/wp-content/themes/surya-digital/images/tilt_border.png"
            alt="Top Border"
            style={{ width: `100%` }}
          />
        </div>
        <ContainerWithHeaderOnly className="references">
          <img
            alt="Surya Digital Logo"
            src="https://www.surya-digital.com/wp-content/uploads/2017/10/slall-heading.png"
          />
          <h3>
            <Trans i18nKey="accompanies the success of its customers" />
          </h3>
          <div className="references-left-div">
            <p className="references-p-first">
              <Trans i18nKey="We build lasting relationships with each of our clients." />
            </p>
            <RenderFlexbox />
          </div>
          <div className="references-right-div">
            <p className="references-p-first" style={{ paddingBottom: `34px` }}>
              <Trans i18nKey="Our long-term partnership policy contributes to your growth in various business sectors." />
            </p>
            <ReferenceList />
          </div>
          <h3
            className="references-lower-header"
            style={{ paddingBottom: `20px` }}
          >
            <Trans i18nKey="Contact us to know our references and deepen our areas of expertise." />
          </h3>
        </ContainerWithHeaderOnly>
      </Layout>
    );
  }
}

export default withTranslation()(References);
